// 底部
<template>
  <div class="footer-bar-container">
    <div class="footer-bar-wrapper">
      <dl class="info-wrapper">
        <dd
          class="info-item"
          v-for="(item, index) of infoList"
          :key="index"
        >
          <div class="item-icon">
            <img :src="`/img/${item.icon}`">
          </div>
          <div class="item-label">
            <span>{{ item.label }}</span>
          </div>
          <div class="item-value">
            <span>{{ item.value }}</span>
          </div>
        </dd>
      </dl>
      <div class="backup-info">
        <span class="info">备案号：</span>
        <a class="value" href="https://beian.miit.gov.cn/" target="blank">鄂ICP备2021004487号-1</a>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  data(){
    return{
      infoList: [
        {
          icon: 'tel.svg',
          label: '联系电话',
          value: '027-63492939'
        },
        {
          icon: 'email.svg',
          label: '电子邮件',
          value: '61341876@qq.com'
        },
        {
          icon: 'address.svg',
          label: '公司地址',
          value: '湖北武汉东湖新技术开发区光谷大道3号未来之光7-904'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.footer-bar-container{
  .footer-bar-wrapper{
    .info-wrapper{
      position: relative;
      padding: 0 392px;
      height: 224px;
      display: flex;
      background-color: #444;
      display: flex;
      justify-content: space-between;
      .info-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        .item-icon{
          padding-top: 40px;
        }
        .item-label{
          position: absolute;
          top: 134px;
          z-index: 99;
          span{
            color: #fff;
            font-size: 16px;
          }
        }
        .item-value{
          position: absolute;
          top: 168px;
          z-index: 99;
          span{
            color: #fff;
            font-size: 16px;
          }
        }
      }
    }
    .backup-info{
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #333;
      span{
        display: flex;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
      .value{
        display: flex;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}
</style>