import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

// 重写replace和push方法
const rawReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location){
  return rawReplace.call(this, location).catch(error => error);
}

const rawPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject){
  if(onResolve || onReject) return rawPush.call(this, location, onResolve, onReject);
  return rawPush.call(this, location).catch(error => error);
}

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;