<template>
  <div id="app">
    <header-bar class="header-bar-component"></header-bar>
    <router-view />
    <footer-bar class="footer-bar-component"></footer-bar>
  </div>
</template>
<script>
import HeaderBar from "@/components/headerbar.vue";
import FooterBar from "@/components/footerbar.vue";

export default{
  components: {
    HeaderBar,
    FooterBar
  }
}
</script>
<style lang="less">
@import '@/assets/global.less';
#app{
  width: 1920px;
  .header-bar-component{
    position: relative;
    z-index: 99;
  }
}
</style>