const routes = [
  {
    path: '/',
    name: 'profile',
    component: () => import('@/views/profile/profile.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product/product.vue')
  },
  {
    path: '/cooperate',
    name: 'cooperate',
    component: () => import('@/views/cooperate/cooperate.vue')
  }
]

export default routes;