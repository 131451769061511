// 顶部导航
<template>
  <div class="header-bar-container">
    <div class="header-bar-wrapper">
      <div class="logo-wrapper">
        <img src="/img/logo.png">
      </div>
      <dl class="navi-bar-wrapper">
        <dd
          v-for="(item, index) of barList"
          :key="index"
          :class="`bar-item ${ cur === index ? 'select-item' : '' }`"
          @click="handleClick(index)"
        >
          <span>{{ item.label }}</span>
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
export default{
  data(){
    return{
      cur: 0,
      barList: [
        {
          label: '公司简介',
          path: '/'
        },
        {
          label: '产品与服务',
          path: '/product'
        },
        {
          label: '合作客户',
          path: '/cooperate'
        }
      ]
    }
  },
  methods: {
    handleClick(index){
      this.cur = index;
      this.$router.push({ path: this.barList[index].path, query: { index } });
    }
  },
  watch: {
    '$route'(val){
      this.cur = val.query.index ? Number(val.query.index) : 0;
    }
  }
}
</script>
<style lang="less" scoped>
.header-bar-container{
  .header-bar-wrapper{
    padding-left: 300px;
    padding-right: 343px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 5px 5px rgba(153, 153, 153, .35);
    .logo-wrapper{
      img{
        width: 200px;
      }
    }
    .navi-bar-wrapper{
      height: 92px;
      width: 412px;
      display: flex;
      justify-content: space-between;
      .bar-item{
        display: flex;
        align-items: center;
        font-size: 16px;
        &.select-item{
          color: #1B82D2;
          position: relative;
          &::before{
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 6px;
            background-color: #199ed8;
          }
        }
        &:not(.select-item){
          cursor: pointer;
          &:hover{
            color: #0099cc;
          }
        }
      }
    }
  }
}
</style>